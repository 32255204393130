@import 'components/layout/styles/Variables';

.App {
  text-align: center;
}

// Global img styles
img {
  max-width: 100%;
}

// Maximum width of content items
.ci-page-wrapper {
  max-width: 1240px;
  padding: 0 10px;
  margin: 0 auto;

  @media (min-width: $screen-sm-min) {
    padding: 0 40px;
  }

  @media (min-width: $screen-lg-min) {
    padding: 0 100px;
  }
}

// Module spacings
.ci-module-spacer {
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
  margin-bottom: 20px;

  @media (min-width: $screen-sm-min) {
    margin-top: 40px;
    margin-bottom: 40px;
  }

  @media (min-width: $screen-lg-min) {
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.ci-module-inner {
  padding: 0 30px;

  @media (min-width: $screen-md-min) {
    padding: 0 40px;
  }

  @media (min-width: $screen-lg-min) {
    padding: 0 105px;
  }
}

.ci-module-title {
  font-family: $ci-font-title;
  font-size: 28px;
  color: $ci-color-blue-10;
  margin: 0 0 20px 0;

  @media (min-width: $screen-lg-min) {
    font-size: 36px;
  }
}

// Button
.ci-button-primary {
  display: inline-flex;
  align-items: center;
  background: $ci-color-blue-10;
  height: 52px;
  padding: 0 22px 0 35px;
  color: #fff;
  font-size: 18px;
  font-weight: bold;
  text-decoration: none;
  transition: 250ms;
  border: 0;

  &.reload {
    background-color: rgb(0, 140, 161);
  }

  &:hover {
    background: $ci-color-blue-20;
  }

  // Button Arrow
  img {
    width: 13px;
    height: 13px;
    margin-left: 16px;
  }
}
