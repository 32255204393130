@import "./Variables";

.app-footer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  margin-top: 30px;

  @media (min-width: $screen-lg-min) {
    margin-top: 100px;
  }

  > * {
    order: 2;

    @media (max-width: $screen-xs-max) {
      width: 100%;
    }
  }
}

.ci-footer-copyright {
  color: #A3A3A3;
  font-size: 14px;
  order: 3;

  @media (min-width: $screen-sm-min) {
    order: 1;
  }
}

.app-nav {
  list-style: none;
  padding: 0;
  margin: 0 -15px;
}
.app-nav li {
  display: inline-block;
  padding: 0;
  margin: 0;
}

/*Link styles*/
.app-footer a {
  display: block;
  color: $ci-color-blue-10;
  padding: 15px;
  text-decoration: none;
  font-weight: bold;
  transition: 250ms;

  &:hover {
    color: $ci-color-blue-20;
  }
}
