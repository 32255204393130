@import '../../layout/styles/Variables';

@keyframes animDown {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes animUp {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0px);
  }
}

@keyframes flyby {
  0% {
    transform: translate(0px, 0) scale(0.3);
    opacity: 0;
  }
  50% {
    transform: translate(40px, 0) scale(1);
    opacity: 1;
  }
  100% {
    transform: translate(100px, 0) scale(0.3);
    opacity: 0;
  }
}

.app-upload-area,
.app-classification-area {
  background-color: #fff;
  color: $ci-color-blue-10;
  padding: 10px;
  margin: auto;
}

.app-upload-area-inner {
  padding: 40px 20px;
  border: $ci-color-blue-10 dashed 2px;
}

.app-classification-area-inner {
  padding: 40px 20px;
}

.ci-upload-indicator {
  margin-bottom: 10px;

  &.progress {
    img {
      animation: flyby 2s infinite ease;
    }
  }

  img {
    width: 100px;
    height: 108px;
  }
}

.ci-fileformat-hint {
  color: $ci-color-grey-05;
  font-size: 14px;
}

.app-classification {
  font-family: 'Netto', sans-serif;
  margin-bottom: 25px;
  margin-top: 25px;
  text-align: left;
}

.app-classification-toggle {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-content: stretch;
  align-items: center;
  background-color: #f2f2f2;
  text-align: right;
  padding: 10px;

  a {
    font-size: 12px;
    color: #464646;
    font-weight: 700;
    display: inline-block;
    text-decoration: none;

    &.close {
      &::after {
        background-image: url('/images/close_accordions.svg');
      }
    }

    &:hover {
      color: #002d64;
    }

    &::after {
      content: '';
      display: inline-block;
      background-image: url('/images/open_accordions.svg');
      width: 12px;
      height: 12px;
      margin-right: 8px;
      margin-left: 8px;
    }
  }
}

.app-classification {
  color: #464646;
}

.app-classification-item-title {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  color: #002d64;
  opacity: 0.75;
  transition: all 0.25s;

  &:hover {
    opacity: 1;

    a.opened {
      animation-name: animDown;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }

    a.closed {
      animation-name: animUp;
      animation-duration: 1s;
      animation-iteration-count: 1;
    }
  }

  h3 {
    margin-left: 25px;
  }

  a {
    display: block;
    width: 22px;
    height: 22px;
    margin-right: 25px;
  }

  &.text-not-found {
    a {
      opacity: 0.4;
    }
  }
}

.app-classification-item-content {
  border-bottom: 1px solid #d0d0d0;
  margin: 0;

  padding-left: 25px;
  padding-right: 25px;

  .app-classification-body,
  .app-classification-header {
    visibility: hidden;
    opacity: 0;
    transition: all 0.15s;
  }

  &.is-open {
    .app-classification-header,
    .app-classification-body {
      visibility: visible;
      opacity: 1;
    }
  }

  &:last-child {
    border-bottom: none;
  }

  &.hidden {
    display: none;
  }
}

.app-classification-item-toggle {
  img {
    transition: all 0.15s;
  }

  &.opened img {
    transform: rotate(0deg);
  }

  &.closed img {
    transform: rotate(180deg);
  }
}

.app-classification-additional-information {
  font-style: italic;
}

.app-classification-precision {
  color: #002d64;
  font-weight: 600;
  font-size: 90%;
}
