@import './Variables';

.app-main {
  background-color: rgb(65, 95, 140);
  background-image: linear-gradient(150deg, #002d64 0%, #415f8c 100%);
  color: #fff;
  padding: 20px 0;

  @media (min-width: $screen-sm-min) {
    padding: 30px 0;
  }

  @media (min-width: $screen-lg-min) {
    padding: 50px 0 80px;
  }

  .ci-module-title {
    color: #fff;
  }
}

.ci-app-main-subtitle {
  font-family: $ci-font-title;
  line-height: 1.5em;
  font-size: 20px;

  @media (min-width: $screen-lg-min) {
    font-size: 24px;
    max-width: 90%;
    margin: 0 auto 40px;
  }
}
