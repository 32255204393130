@import '../../layout/styles/Variables';

.app-language-chooser {
  padding-left: 10px;
  font-family: 'Netto', sans-serif;
  border-left: 1px solid $ci-color-grey-20;
  margin-left: 10px;
  font-size: 90%;

  .item {
    text-transform: uppercase;
    padding-left: 2px;
    padding-right: 2px;
    text-decoration: none;

    &.active {
      text-decoration: underline;
    }
  }

  .Dropdown-control, .Dropdown-menu {
    width: 64px;
  }
}
