@import "./Variables";

.app-how-it-works-list {
  @media (min-width: $screen-md-min) {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;
    margin-top: 40px;
  }
}

.ci-how-it-works-item {
  display: flex;
  text-align: left;
  margin: 0 0 20px;
  color: $ci-color-blue-10;
  padding: 0 15px;

  &:last-child {
    margin: 0;
  }

  @media (max-width: $screen-sm-max) {
    padding: 0;

    > * {
      &:first-child {
        margin-right: 20px;
      }
    }
  }

  @media (min-width: $screen-md-min) {
    display: block;
    text-align: center;
    margin: 0;
  }
}

.ci-arrow-spacer {
  display: none;

  @media (min-width: $screen-md-min) {
    display: block;
    align-self: flex-start;
    padding-top: 6%;
  }

  &:last-child {
    display: none;
  }
}

.ci-step-title {
  font-family: $ci-font-title;
  font-size: 22px;
  margin: 15px 0 10px;
}
