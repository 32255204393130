@import "./Variables";

.app-box-grey {
  background-color: $ci-color-grey-20;
  padding: 30px;

  @media (min-width: $screen-md-min) {
    display: flex;
    flex-direction: row-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    align-content: stretch;
    padding: 30px 40px;
  }

  @media (min-width: $screen-lg-min) {
    padding: 60px 105px;
  }

  > * {
    flex: 1 0 100%;

    @media (min-width: $screen-md-min) {
      flex: 0 0 49%;
    }
  }

  .ci-info-container {
    @media (max-width: $screen-sm-max) {
      margin-top: 20px;
    }

    @media (min-width: $screen-md-min) {
      flex: 0 0 38%;
    }
  }

  .ci-title {
    font-family: $ci-font-title;
    font-size: 26px;
    color: $ci-color-blue-10;
    margin: 0 0 20px 0;

    @media (min-width: $screen-lg-min) {
      font-size: 30px;
    }
  }

  .ci-info {
    font-family: $ci-font-title;
    margin: 0 0 30px 0;

    @media (min-width: $screen-lg-min) {
      font-size: 22px;
    }
  }
}

.text-align-left {
  text-align: left;
}
