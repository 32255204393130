@import './Variables';

.app-header {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  margin: 8px 0;
  height: 36px;
  transition: all 0.35s;

  @media (min-width: $screen-lg-min) {
    height: 44px;
    margin: 12px 0 14px;
  }
}

.app-header-logos,
.app-header-name {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  height: 36px;

  @media (min-width: $screen-lg-min) {
    height: 44px;
  }
}

.app-header-logo-bertelsmann {
  .logo-full {
    width: 120px;
    height: 13px;
    display: none;

    @media (min-width: $screen-lg-min) {
      width: 189px;
      height: 20px;
    }

    @media (min-width: $screen-md-min) {
      display: inherit;
    }
  }

  .logo-short {
    display: inherit;
    width: 24px;

    @media (min-width: $screen-md-min) {
      display: none;
    }
  }
}

.app-header-name {
  font-family: $ci-font-title;
  font-size: 18px;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid $ci-color-grey-20;
  text-transform: uppercase;

  @media (min-width: $screen-sm-min) {
    font-size: 26px;
  }

  img {
    width: 17px;
    height: 20px;
    margin-right: 10px;

    @media (min-width: $screen-lg-min) {
      width: 30px;
      height: 35px;
      margin-right: 18px;
    }
  }
}

// Logout
.ci-header-logout {
  font-family: $ci-font-title;
  color: $ci-color-blue-10;
  text-decoration: none;
  font-size: 18px;
  transition: 250ms;

  &:hover {
    color: $ci-color-blue-20;
  }

  img {
    width: 18px;
    height: 17px;
    margin-left: 10px;
    vertical-align: top;

    @media (max-width: $screen-xs-max) {
      padding: 0 10px;
    }
  }

  // Text should be invisible on mobile
  @media (max-width: $screen-xs-max) {
    span {
      display: none;
    }
  }
}
.app-upload-area .ci-header-logout {
  @media (max-width: $screen-xs-max) {
    span {
      display: inherit;
    }
  }
}

.app-auth-language {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}
