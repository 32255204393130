// Breakpoints
$screen-xs-max: 480px;
$screen-sm-min: 481px;
$screen-sm-max: 768px;
$screen-md-min: 769px;
$screen-md-max: 959px;
$screen-lg-min: 960px;

// Colors
$ci-color-blue-10: #002D64;
$ci-color-blue-20: #415F8C;

$ci-color-grey-05: #a0a0a0;
$ci-color-grey-10: #CDCDCD;
$ci-color-grey-20: #f2f2f2;

// Fonts
@font-face {
  font-family: "Netto";
  src: url("./fonts/NettoWebPro.eot"); /* IE9 Compat Modes */
  src: url("./fonts/NettoWebPro.woff") format("woff"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

$ci-font-title: "Netto";